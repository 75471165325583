import React, { Component } from "react";
import { connect } from "react-redux";
import Popover from "../../components/uielements/popover";
// import IntlMessages from '../../components/utility/intlMessages';
import userpic from "../../image/user1.png";
import authAction from "../../redux/auth/actions";
import TopbarDropdownWrapper from "./topbarDropdown.style";
import { withRouter } from "react-router-dom";

const { login, logout } = authAction;

const S3BucketImageURL = process.env.REACT_DEFAULT_IMAGE;
class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.state = {
      visible: false,
      imgURL: S3BucketImageURL,
      is_admin: false,
      is_shop_admin: false,
    };
    // if (this.props.user && this.props.user != null) {
    //   this.setState({
    //     imgURL: this.props.user.image ? this.props.user.image : S3BucketImageURL,
    //     is_admin: this.props.user.is_admin
    //   });
    // }
  }

  componentDidMount = () => {
    this.setState({
      imgURL: this.props.user ? this.props.user.image : S3BucketImageURL,
      is_admin: this.props.user ? this.props.user.is_admin : false,
      is_shop_admin: this.props.is_shop_manager
        ? this.props.user.is_shop_manager
        : false,
    });
  };
  componentWillReceiveProps = (nextProps) => {
    if (nextProps !== this.props) {
      if (nextProps.user && nextProps.user != null) {
        this.setState({
          imgURL: nextProps.user.image
            ? nextProps.user.image
            : S3BucketImageURL,
          is_admin: nextProps.user.is_admin,
          is_shop_admin: nextProps.user.is_shop_manager,
        });
      }
    }
  };

  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  _gotToProfile = () => {
    this.setState({ visible: !this.state.visible });
    this.props.history.push("/admin/edit-profile");
  };

  _goToChangePassword = () => {
    this.setState({ visible: !this.state.visible });
    this.props.history.push("/admin/change-password");
  };
  _goToChangePasswordShop = () => {
    this.setState({ visible: !this.state.visible });
    this.props.history.push("/admin/change-password-shop");
  };
  _goToChangePasswordShopManager = () => {
    this.setState({ visible: !this.state.visible });
    this.props.history.push("/admin/change-password-shop-manager");
  };
  _logoutCall = () => {
    this.props.history.push("/signin");
    this.props.logout();
  };
  _logoutShopCall = () => {
    this.props.history.push("/signin-shop");
    this.props.logout();
  };
  _logoutShopManager = () => {
    this.props.history.push("/signin-shop-manager");
    this.props.logout();
  };

  render() {
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        {this.state.is_admin ? (
          <React.Fragment>
            <a className="isoDropdownLink" onClick={this._gotToProfile}>
              My Profile
            </a>
            <a className="isoDropdownLink" onClick={this._goToChangePassword}>
              Change Password
            </a>
            <a className="isoDropdownLink" onClick={this._logoutCall}>
              Logout
            </a>
          </React.Fragment>
        ) : this.state.is_shop_admin ? (
          <React.Fragment>
            <a
              className="isoDropdownLink"
              onClick={this._goToChangePasswordShopManager}
            >
              Change Password
            </a>
            <a className="isoDropdownLink" onClick={this._logoutShopManager}>
              Logout
            </a>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <a
              className="isoDropdownLink"           
              onClick={this._goToChangePasswordShop}
            >
              Change Password
            </a>
            <a className="isoDropdownLink" onClick={this._logoutShopCall}>
              Logout
            </a>
          </React.Fragment>
        )}
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div style={{ paddingTop: "12px" }}>
          {}
          <img
            alt="user"
            src={this.state.imgURL}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </div>
      </Popover>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      user: state.Auth.get("user"),
      token: state.Auth.get("idToken"),
    }),
    { logout }
  )(TopbarUser)
);
