import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import asyncComponent from "../../helpers/AsyncFunc";

const routes = [
  {
    path: "",
    component: asyncComponent(() => import("../dashboard")),
  },
  {
    path: "dashboard",
    component: asyncComponent(() => import("../dashboard")),
  },
  {
    path: "blankPage",
    component: asyncComponent(() => import("../blankPage")),
  },
  {
    path: "change-password",
    component: asyncComponent(() =>
      import("../Page/change_password/changePassword")
    ),
  },
  {
    path: "users",
    component: asyncComponent(() => import("../Page/users/users")),
  },
  {
    path: "edit-profile",
    component: asyncComponent(() => import("../Page/edit_profile/editProfile")),
  },
  {
    path: "enquiries",
    component: asyncComponent(() => import("../Page/enquiries/enquiries")),
  },
  {
    path: "static-pages",
    component: asyncComponent(() => import("../Page/static_page/staticPages")),
  },
  {
    path: "category",
    component: asyncComponent(() => import("../Page/category/category")),
  },
  {
    path: "mall-management",
    component: asyncComponent(() =>
      import("../Page/mall_management/mallManagement")
    ),
  },
  {
    path: "mall-management/add-mall",
    component: asyncComponent(() =>
      import("../Page/mall_management/addMallModal")
    ),
  },
  {
    path: "mall-management/:id/edit-mall",
    component: asyncComponent(() =>
      import("../Page/mall_management/editMallModal")
    ),
  },
  {
    path: "drivers",
    component: asyncComponent(() => import("../Page/drivers/drivers")),
  },
  {
    path: "collectors",
    component: asyncComponent(() => import("../Page/collector/collector")),
  },
  {
    path: "shops",
    component: asyncComponent(() => import("../Page/shop/shops")),
  },
  {
    path: "popular-shop",
    component: asyncComponent(() =>
      import("../Page/popular_shop/popularShops")
    ),
  },
  {
    path: "shops/:mall_id",
    component: asyncComponent(() => import("../Page/shop/shops")),
  },
  {
    path: "subcategories",
    component: asyncComponent(() => import("../Page/sub_category/subcategory")),
  },
  {
    path: "admin-setting",
    component: asyncComponent(() =>
      import("../Page/admin_setting/adminSetting")
    ),
  },
  {
    path: "products/:id",
    component: asyncComponent(() => import("../Page/product/products")),
  },
  {
    path: "shops/order/:id",
    component: asyncComponent(() => import("../Page/shop/shop-orders/orders")),
  },
  {
    path: "shops/rejected-order/:id",
    component: asyncComponent(() =>
      import("../Page/shop/rejected-order/rejectedOrders")
    ),
  },
  {
    path: "collector/order/:id",
    component: asyncComponent(() =>
      import("../Page/collector/collector-order/orders")
    ),
  },
  {
    path: "users/order/:id",
    component: asyncComponent(() => import("../Page/users/user-order/orders")),
  },
  {
    path: "drivers/order/:id",
    component: asyncComponent(() =>
      import("../Page/drivers/driver-order/orders")
    ),
  },
  {
    path: "orders",
    component: asyncComponent(() => import("../Page/orders/orders")),
  },
  {
    path: "cancelled",
    component: asyncComponent(() =>
      import("../Page/cancelled_orders/cancelled-orders")
    ),
  },
  {
    path: "orders/:promo_id",
    component: asyncComponent(() => import("../Page/orders/orders")),
  },
  {
    path: "offers",
    component: asyncComponent(() => import("../Page/offers/offers")),
  },
  {
    path: "combo-offer",
    component: asyncComponent(() => import("../Page/combo_offers/offers")),
  },
  {
    path: "promotional-coupon",
    component: asyncComponent(() =>
      import("../Page/promotional-coupen/coupen")
    ),
  },
  {
    path: "email-template",
    component: asyncComponent(() =>
      import("../Page/email_template/emailTemplate")
    ),
  },
  {
    path: "email-notification",
    component: asyncComponent(() =>
      import("../Page/email_notification/emailTemplate")
    ),
  },
  // dashboard
  {
    path: "shop-desk",
    component: asyncComponent(() =>
      import("../Page/sub-admin/src/components/dashboard/dashboard")
    ),
  },
  {
    path: "change-password-shop",
    component: asyncComponent(() =>
      import("../Page/change_password/changePasswordShop")
    ),
  },
  {
    path: "change-password-shop-manager",
    component: asyncComponent(() =>
      import("../Page/change_password/changePasswordShopManager")
    ),
  },
  {
    path: "shop-products",
    component: asyncComponent(() =>
      import("../Page/sub-admin/src/components/product/products")
    ),
  },
  {
    path: "shop-manager-products",
    component: asyncComponent(() =>
      import("../Page/sub-admin/src/components/shopProduct/products")
    ),
  },
  {
    path: "shop-manager-reports",
    component: asyncComponent(() =>
      import("../Page/sub-admin/src/components/managerReports/reports")
    ),
  },
  {
    path: "broadcast",
    component: asyncComponent(() =>
      import("../Page/broadcast_notifications/broadcast")
    ),
  },
  {
    path: "customized-reports",
    component: asyncComponent(() =>
      import("../Page/customized_reports/reportsIndex")
    ),
  },
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map((singleRoute) => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
        {/* <Redirect to={`/404`} /> */}
      </div>
    );
  }
}

export default AppRouter;
