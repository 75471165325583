const options = [
  {
    key: "dashboard",
    label: "Dashboard",
    leftIcon: "fas fa-tachometer-alt",
    is_admin: true,
  },
  {
    key: "users",
    label: "Users",
    leftIcon: "fas fa-users",
    is_admin: true,
  },

  {
    key: "mall-management",
    label: "Mall Management",
    leftIcon: "fas fa-map-marker-alt",
    is_admin: true,
  },
  {
    key: "shops",
    label: "Shops",
    leftIcon: "fas fa-hotel",
    is_admin: true,
  },
  {
    key: "collectors",
    label: "Collectors",
    leftIcon: "fas fa-user",
    is_admin: true,
  },
  {
    key: "drivers",
    label: "Drivers",
    leftIcon: "fas fa-user",
    is_admin: true,
  },

  {
    key: "category",
    label: "Categories",
    leftIcon: "fas fa-utensils",
    is_admin: true,
  },
  {
    key: "subcategories",
    label: "SubCategories",
    leftIcon: "fas fa-utensils",
    is_admin: true,
  },

  {
    key: "enquiries",
    label: "Enquiries",
    leftIcon: "fas fa-comment-alt",
    is_admin: true,
  },
  {
    key: "static-pages",
    label: "Static pages",
    leftIcon: "fas fa-sticky-note",
    is_admin: true,
  },

  {
    key: "admin-setting",
    label: "Admin Settings",
    leftIcon: "fas fa-cog",
    is_admin: true,
  },
  {
    key: "cancelled",
    label: "Cancelled Orders",
    leftIcon: "fas fa-shopping-cart",
    is_admin: true,
  },
  {
    key: "orders",
    label: "Orders",
    leftIcon: "fas fa-shopping-cart",
    is_admin: true,
  },
  {
    key: "offers",
    label: "Offers",
    leftIcon: "fas fa-percent",
    is_admin: true,
  },
  {
    key: "combo-offer",
    label: "Combo Offers",
    leftIcon: "fas fa-percent",
    is_admin: true,
  },
  {
    key: "email-notification",
    label: "Email Notification",
    leftIcon: "ion-android-mail",
    is_admin: true,
  },
  {
    key: "email-template",
    label: "Email Templates",
    leftIcon: "ion-android-mail",
    is_admin: true,
  },
  {
    key: "popular-shop",
    label: "Popular Shops",
    leftIcon: "fas fa-hotel",
    is_admin: true,
  },
  {
    key: "promotional-coupon",
    label: "Promotional Coupons",
    leftIcon: "fas fa-percent",
    is_admin: true,
  },
  {
    key: "broadcast",
    label: "Broadcast Notifications",
    leftIcon: "fas fa-bell",
    is_admin: true,
  },
  {
    key: "customized-reports",
    label: "Customized Reports",
    leftIcon: "fas fa-file",
    is_admin: true,
  },
  // sub admin routes

  {
    key: "shop-desk",
    label: "Shop Dashboard",
    leftIcon: "fas fa-tachometer-alt",
    is_admin: false,
  },
  {
    key: "shop-products",
    label: "Products",
    leftIcon: "fas fa-info-circle",
    is_admin: false,
  },
  // Shop manager routes
  {
    key: "shop-manager-products",
    label: "Products",
    leftIcon: "fas fa-info-circle",
    is_shop_admin: true,
  },
  {
    key: "shop-manager-reports",
    label: "Customized Reports",
    leftIcon: "fas fa-info-circle",
    is_shop_admin: true,
  },
];
export default options;
