export default {
  apiUrl: "http://yoursite.com/api/"
};

const siteConfig = {
  siteName: "Click Mall",
  siteIcon: "ion-flash",
  footerText: "ClickMall ©2017"
};
const themeConfig = {
  topbar: "themedefault",
  sidebar: "themedefault",
  layout: "themedefault",
  theme: "themedefault"
};
const language = "english";
export { siteConfig, language, themeConfig };
