import React from "react";
import { Link } from "react-router-dom";
import { siteConfig } from "../../settings";
import logo from "../../image/click-mall-icon.png";

export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to={{
              hash: "#",
            }}>
              <img
                src={logo}
                alt="Icon"
                style={{ height: "50px", width: "50px" }}
              />
            </Link>
          </h3>
        </div>
      ) : (
          <h3>
            <Link to={{
              hash: "#",
            }}>
              {siteConfig.siteName}
            </Link>
          </h3>
        )}
    </div>
  );
};



